// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/images/misc/knowledge-base-bg-light.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "#faq .faq-bg{padding:5.5rem;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:cover}#faq .faq-search-input{max-width:28.125rem;background-color:#fff;border-radius:5px}@media(max-width:600px){#faq .faq-bg{padding:1.5rem!important}}.v-application.theme--light .faq-contact{background-color:rgba(94,86,105,.04)}.v-application.theme--dark .faq-contact{background-color:rgba(231,227,252,.04)}", ""]);
// Exports
module.exports = exports;
